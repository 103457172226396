import { render, staticRenderFns } from "./TableBuilder.vue?vue&type=template&id=6830e2c9&"
import script from "./TableBuilder.vue?vue&type=script&lang=ts&"
export * from "./TableBuilder.vue?vue&type=script&lang=ts&"
import style0 from "./TableBuilder.vue?vue&type=style&index=0&id=6830e2c9&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCard,VCardActions,VChip,VCol,VDataTable,VRow,VSpacer,VSwitch})
