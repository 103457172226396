export default {
    MODE: "test",
    NAME_SPACE: "backoffice",
    RECAPTCHA_SITE_KEY: "6LccyT8cAAAAADWd6c0pmRRhn7cpMN5u-AYp_KzG",
    FIREBASE_CONFIG: {
        apiKey: "AIzaSyA1tCxh0ShzGOAUNPC9ygPN5IN-RAENsPM",
        authDomain: "drinking-game-test.firebaseapp.com",
        projectId: "drinking-game-test",
        storageBucket: "drinking-game-test.appspot.com",
        messagingSenderId: "524353425807",
        appId: "1:524353425807:web:b9ed381d7ea63ffb063bfa",
        measurementId: "G-3KC4188VEQ",
    },
};
