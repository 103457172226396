import firebaseApp from "@/firebaseApp";
import { showMessage } from "@/helpers/messages";
import Vue from "vue";
import {
    WriteBatch,
    UpdateData,
    DocumentReference,
    DocumentData,
    Query,
    WhereFilterOp,
    getFirestore,
    collection,
    where,
    query,
    orderBy,
    startAfter,
    startAt,
    documentId,
    limit,
    QueryConstraint,
    onSnapshot,
    getDocs,
    getDoc,
    setDoc,
    updateDoc,
    deleteDoc,
    doc,
    FirestoreError,
    writeBatch,
    FieldPath,
    Unsubscribe,
    OrderByDirection,
} from "firebase/firestore";

type LocalWhereFilterOp = "array-not-contains";
export type FindOptionsWhereFilter = {
    fieldPath: string;
    opStr: WhereFilterOp | LocalWhereFilterOp;
    value: any;
};
export type FindOptionsQuery = (
    query: Query<DocumentData>
) => Query<DocumentData>;
export type FindOptions = {
    queryConstraints?: QueryConstraint[];
    whereFilters?: FindOptionsWhereFilter[];
    startAfter?: string;
    startAt?: string;
    limit?: number;
    orderBy?: {
        fieldPath: string | FieldPath;
        direction?: OrderByDirection;
    };
};
const findAllQuery = (
    collectionName: string,
    options: FindOptions
): Query<DocumentData> => {
    const firestore = getFirestore(firebaseApp);
    const collectionRef = collection(firestore, collectionName);

    let queryConstraints: QueryConstraint[] = [];
    if (options.queryConstraints != undefined) {
        queryConstraints.push(...options.queryConstraints);
    }
    if (options.whereFilters != undefined) {
        for (const whereFilter of options.whereFilters) {
            // Apply remote firestore filters
            if (whereFilter.opStr != "array-not-contains") {
                queryConstraints.push(
                    where(
                        whereFilter.fieldPath,
                        whereFilter.opStr,
                        whereFilter.value
                    )
                );
            }
        }
    }
    if (options.orderBy != undefined) {
        queryConstraints.push(
            orderBy(
                options.orderBy.fieldPath ?? documentId(),
                options.orderBy.direction
            )
        );
    } else {
        queryConstraints.push(orderBy(documentId()));
    }
    if (options.startAfter != undefined) {
        queryConstraints.push(startAfter(options.startAfter));
    }
    if (options.startAt != undefined) {
        queryConstraints.push(startAt(options.startAt));
    }
    if (options.limit != undefined) {
        queryConstraints.push(limit(options.limit));
    }
    return query(collectionRef, ...queryConstraints);
};

// Returns filtered results
function applyLocalFilter(
    results: DocumentData[],
    whereFilters: FindOptionsWhereFilter[],
    limit: number
): DocumentData[] {
    const localWhereFilters = whereFilters.filter(
        (el) => el.opStr == "array-not-contains"
    );
    if (localWhereFilters.length > 0) {
        const newResults: DocumentData[] = [];
        for (const item of results) {
            for (const whereFilter of localWhereFilters) {
                const valueAtKeyPath = whereFilter.fieldPath
                    .split(".")
                    .reduce((previous, current) => previous[current], item);
                if (!valueAtKeyPath.includes(whereFilter.value)) {
                    newResults.push(item);
                }
            }
            if (newResults.length > limit) {
                break;
            }
        }
        return newResults;
    } else {
        return results;
    }
}
const observeAll = (
    collectionName: string,
    options: FindOptions,
    onNext: (result: DocumentData[]) => void,
    onError: (error: { code: string; message: string }) => void
): Unsubscribe => {
    const queryLimit = options.limit ?? 10;
    Vue.showLoadingOverlay(true);
    const unsubscribe = onSnapshot(
        findAllQuery(collectionName, options),
        (result) => {
            Vue.showLoadingOverlay(false);
            const mappedResult = result.docs.map((el) => {
                return {
                    id: el.id,
                    ...el.data(),
                };
            });
            const filteredResults = applyLocalFilter(
                mappedResult,
                options.whereFilters ?? [],
                queryLimit
            );
            if (
                filteredResults.length < queryLimit &&
                mappedResult.length >= queryLimit
            ) {
                // Insufficient records fetched, try to parse more
                unsubscribe();
                return observeAll(
                    collectionName,
                    {
                        ...options,
                        limit: queryLimit * 2,
                    },
                    onNext,
                    onError
                );
            } else {
                onNext(filteredResults);
            }
        },
        (error) => {
            console.log("Eeeerror", collectionName, options, error);
            Vue.showLoadingOverlay(false);
            showMessage({
                text: error.message,
                type: "error",
            });
            onError(error);
        }
    );
    return unsubscribe;
};
const findAll = async <T>(
    collectionName: string,
    options: FindOptions
): Promise<T[]> => {
    Vue.showLoadingOverlay(true);
    try {
        const result = await getDocs(findAllQuery(collectionName, options));
        Vue.showLoadingOverlay(false);
        const mappedResult = result.docs.map((el) => {
            return {
                id: el.id,
                ...el.data(),
            };
        });
        return (mappedResult as unknown) as T[];
    } catch (error) {
        Vue.showLoadingOverlay(false);
        showMessage({
            text: (error as FirestoreError).message,
            type: "error",
        });
        throw error;
    }
};
const getNewDocumentId = (collectionName: string): string => {
    const firestore = getFirestore(firebaseApp);
    return doc(collection(firestore, collectionName)).id;
};
const find = async <T>(
    collectionName: string,
    documentId: string
): Promise<T | undefined> => {
    Vue.showLoadingOverlay(true);
    const firestore = getFirestore(firebaseApp);
    const docRef = doc(collection(firestore, collectionName), documentId);
    try {
        const result = await getDoc(docRef);
        const data = result.data();
        Vue.showLoadingOverlay(false);
        if (data != undefined) {
            return ({
                id: result.id,
                ...data,
            } as unknown) as T;
        } else {
            return undefined;
        }
    } catch (error) {
        Vue.showLoadingOverlay(false);
        showMessage({
            text: (error as FirestoreError).message,
            type: "error",
        });
        throw error;
    }
};
const observe = (
    collectionName: string,
    documentId: string,
    onNext: (result?: DocumentData) => void,
    onError: (error: { code: string; message: string }) => void
): void => {
    Vue.showLoadingOverlay(true);
    const firestore = getFirestore(firebaseApp);
    const docRef = doc(collection(firestore, collectionName), documentId);
    onSnapshot(
        docRef,
        (result) => {
            Vue.showLoadingOverlay(false);
            const data = result.data();
            if (data != null) {
                onNext({
                    id: result.id,
                    ...data,
                });
            } else {
                onNext(undefined);
            }
        },
        (error) => {
            Vue.showLoadingOverlay(false);
            showMessage({
                text: error.message,
                type: "error",
            });
            onError(error);
        }
    );
};
type SetOptions = {
    // If it is not set it will be generated
    documentId?: string;
    data: DocumentData;
    merge?: boolean;
};
const set = async (
    collectionName: string,
    options: SetOptions
): Promise<{ documentId: string }> => {
    Vue.showLoadingOverlay(true);
    try {
        const firestore = getFirestore(firebaseApp);
        const docRef = doc(
            collection(firestore, collectionName),
            options.documentId
        );
        await setDoc(docRef, options.data, { merge: options.merge });
        Vue.showLoadingOverlay(false);
        return { documentId: docRef.id };
    } catch (error) {
        Vue.showLoadingOverlay(false);
        showMessage({
            text: (error as FirestoreError).message,
            type: "error",
        });
        console.error(`Save obj ${collectionName} failed`, error);
        throw error;
    }
};
type UpdateOptions = {
    // If it is nil it will be generated
    documentId: string;
    data: UpdateData<any>;
};
const update = async (
    collectionName: string,
    options: UpdateOptions
): Promise<{ documentId: string }> => {
    Vue.showLoadingOverlay(true);
    try {
        const firestore = getFirestore(firebaseApp);
        const docRef = doc(
            collection(firestore, collectionName),
            options.documentId
        );
        await updateDoc(docRef, options.data);
        Vue.showLoadingOverlay(false);
        return { documentId: docRef.id };
    } catch (error) {
        Vue.showLoadingOverlay(false);
        showMessage({
            text: (error as FirestoreError).message,
            type: "error",
        });
        throw error;
    }
};
const remove = async (
    collectionName: string,
    documentId: string
): Promise<void> => {
    Vue.showLoadingOverlay(true);
    try {
        const firestore = getFirestore(firebaseApp);
        const docRef = doc(collection(firestore, collectionName), documentId);
        await deleteDoc(docRef);
        Vue.showLoadingOverlay(false);
        return;
    } catch (error) {
        Vue.showLoadingOverlay(false);
        showMessage({
            text: (error as FirestoreError).message,
            type: "error",
        });
        throw error;
    }
};
const documentReference = (
    collectionName: string,
    documentId: string
): DocumentReference<DocumentData> => {
    const firestore = getFirestore(firebaseApp);
    const docRef = doc(collection(firestore, collectionName), documentId);
    return docRef;
};
const batch = async (exec: (writeBatch: WriteBatch) => void): Promise<void> => {
    Vue.showLoadingOverlay(true);
    try {
        const firestore = getFirestore(firebaseApp);
        const writeBatchInstance = writeBatch(firestore);
        exec(writeBatchInstance);
        await writeBatchInstance.commit();
        Vue.showLoadingOverlay(false);
        return;
    } catch (error) {
        Vue.showLoadingOverlay(false);
        showMessage({
            text: (error as FirestoreError).message,
            type: "error",
        });
        throw error;
    }
};

export default {
    getNewDocumentId,
    findAll,
    observeAll,
    find,
    observe,
    update,
    set,
    remove,
    documentReference,
    batch,
};
