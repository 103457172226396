import firestoreRepository from "./firestoreRepository";

const COLLECTION_NAME = "settings";
const CARD_TAGS_KEY = "card_tags";

const observeCardTags = async (
    onNext: (result: string[]) => void,
    onError?: (error: { code: string; message: string }) => void
): Promise<void> => {
    firestoreRepository.observe(
        COLLECTION_NAME,
        CARD_TAGS_KEY,
        (next) => {
            onNext(
                Object.entries(next?.value)
                    .filter(([_, value]) => (value as any).count > 0)
                    .map(([key, _]) => key)
            );
        },
        (error) => {
            onError?.(error);
        }
    );
};
export default {
    COLLECTION_NAME,
    CARD_TAGS_KEY,
    observeCardTags,
};
