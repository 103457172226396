import { render, staticRenderFns } from "./ColorField.vue?vue&type=template&id=b6aba876&"
import script from "./ColorField.vue?vue&type=script&lang=js&"
export * from "./ColorField.vue?vue&type=script&lang=js&"
import style0 from "./ColorField.vue?vue&type=style&index=0&id=b6aba876&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCardText,VColorPicker,VMenu,VTextField})
