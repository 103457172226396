<template>
    <v-text-field
        :value="model[getModelKeyPath()]"
        @input="setComputedBindedValue($event)"
        :type="field.inputType"
        :label="field.label"
        :placeholder="field.label"
        :readonly="field.readonly"
        :min="field.min"
        :max="field.max"
        :maxlength="field.maxLength"
        :counter="field.counter"
    ></v-text-field>
</template>

<script>
import elementsMixin from "./elementsMixin";
import formElementsUtils from "./formElementsUtils";
import Vue from "vue";
export default {
    name: "textField",
    mixins: [elementsMixin],
    mounted() {
        if (!this.model[this.element] && this.field.preCompiled) {
            if (typeof this.field.preCompiled == "function") {
                this.$set(this.model, this.element, this.field.preCompiled());
            }
        }
    },
    methods: {
        getModelKeyPath() {
            const keyPath = formElementsUtils.getComputedKeyPath(
                this.element,
                this.model
            );
            return keyPath;
        },
        setComputedBindedValue(value) {
            const keyPath = formElementsUtils.getComputedKeyPath(
                this.element,
                this.model
            );
            Vue.set(this.model, keyPath, value);
        },
    },
};
</script>

<style lang="scss">
.text-field-line-height {
    height: 5px;
}
</style>
