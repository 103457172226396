import Vue from "vue";
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
import vuetify from "@/plugins/vuetify";
// @ts-ignore
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { showMessage } from "./helpers/messages";
import VueUUID from "vue-uuid";
import env from "@/env";
import VueI18n from "vue-i18n";
import { DICTIONARY } from "./languages/en";

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: "en", // set locale
    messages: DICTIONARY, // set locale messages
});

// @ts-ignore
Vue.use(VueUUID);

Vue.config.productionTip = false;
Vue.prototype.$message = showMessage;
Vue.prototype.$env = env;

import FormBuilderVuetify from "./plugins/components/builders/form-builder-vuetify/src/main";
Vue.use(FormBuilderVuetify);
import TableBuilderVuetify from "./plugins/components/builders/table-builder-vuetify/src/main";
Vue.use(TableBuilderVuetify);
import PaginatedTableBuilderVuetify from "./plugins/components/builders/paginated-table-builder-vuetify/src/main";
Vue.use(PaginatedTableBuilderVuetify);
import ConfirmDialogVuetify from "./plugins/components/ui/dialog/confirm-dialog-vuetify/src/main";
Vue.use(ConfirmDialogVuetify);

import { LoadingOverlay } from "./plugins/components/ui/loadingOverlay";
Vue.use(LoadingOverlay);

export class EventBus {
    private vue: Vue;

    constructor() {
        this.vue = new Vue();
    }

    emit(event: string, data = null): void {
        this.vue.$emit(event, data);
    }

    listen(event: string, callback): void {
        this.vue.$on(event, callback);
    }

    off(event: string, callback): void {
        this.vue.$off(event, callback);
    }
}
// @ts-ignore
window.EventBus = new EventBus();

new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
