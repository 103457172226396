// @ts-nocheck
import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

const opts = {
    icons: {
        iconfont: "mdi",
    },
    theme: {
        themes: {
            light: {
                primary: colors.indigo.darken3,
                secondary: colors.grey.darken3, // #E53935
                background: colors.grey.lighten3,
                textReverse: colors.shades.white,
                accent: colors.blue.darken2, // #3F51B5,
                danger: colors.red,
            },
        },
    },
};

export default new Vuetify(opts);
