import FormBuilderVuetify from "./components/FormBuilder.vue";
import _Vue from "vue";

export default {
    install(Vue: typeof _Vue): void {
        Vue.component("form-builder-vuetify", FormBuilderVuetify);
    },
};

export { FormBuilderVuetify };
