import Card from "@/model/Card";
import Deck from "@/model/Deck";
import cardRepository from "./cardRepository";
import firestoreRepository from "./firestoreRepository";
import _ from "lodash-es";
import { increment, arrayUnion, arrayRemove } from "firebase/firestore";
import DeckDetails from "@/model/DeckDetails";

const COLLECTION_NAME = "decks";
const DECKS_DETAILS_COLLECTION_NAME = "decksDetails";
const getNewDeckId = (): string => {
    return firestoreRepository.getNewDocumentId(COLLECTION_NAME);
};

const findDeck = async (id: string): Promise<Deck | undefined> => {
    return await firestoreRepository.find(COLLECTION_NAME, id);
};
const observeDeck = (
    id: string,
    onNext: (result?: Deck) => void,
    onError: (error: { code: string; message: string }) => void
): void => {
    firestoreRepository.observe(COLLECTION_NAME, id, onNext as any, onError);
};
const observeDeckDetails = (
    id: string,
    onNext: (result?: DeckDetails) => void,
    onError: (error: { code: string; message: string }) => void
): void => {
    firestoreRepository.observe(
        DECKS_DETAILS_COLLECTION_NAME,
        id,
        onNext as any,
        onError
    );
};
const removeDeck = async (id: string): Promise<void> => {
    const deck = await findDeck(id);
    if (deck == undefined) return;
    const deckCards = await cardRepository.findCards({ deckId: id });
    if (deckCards.length > 400) {
        throw "Currently it is not supported to delete a deck with more than 400 cards, please remove some cards before deleting it.";
    }
    await firestoreRepository.batch((writeBatch) => {
        for (const deckCard of deckCards) {
            writeBatch.update(
                firestoreRepository.documentReference(
                    cardRepository.COLLECTION_NAME,
                    deckCard.id
                ),
                {
                    deckIds: arrayRemove(deck.id),
                }
            );
        }
        writeBatch.delete(
            firestoreRepository.documentReference(
                DECKS_DETAILS_COLLECTION_NAME,
                id
            )
        );
        writeBatch.delete(
            firestoreRepository.documentReference(COLLECTION_NAME, id)
        );
    });
};
const saveDeck = async (deck: Deck): Promise<void> => {
    const decks = await firestoreRepository.findAll(COLLECTION_NAME, {});
    const deckClone: Partial<Deck> = _.clone(deck);
    delete deckClone.id;
    deckClone.numberOfCardsPerGame = parseInt(deck.numberOfCardsPerGame as any);
    if (isNaN(deckClone.numberOfCardsPerGame)) {
        throw "Invalid numberOfCardsPerGame";
    }
    deckClone.position = decks.length + 1;
    await firestoreRepository.batch((writeBatch) => {
        writeBatch.set(
            firestoreRepository.documentReference(COLLECTION_NAME, deck.id),
            deckClone,
            { merge: true }
        );
        writeBatch.set(
            firestoreRepository.documentReference(
                DECKS_DETAILS_COLLECTION_NAME,
                deck.id
            ),
            {},
            { merge: true }
        );
    });
};
const moveDeck = async (
    deck: Deck,
    oldIndex: number,
    newIndex: number
): Promise<void> => {
    const decks: Deck[] = await firestoreRepository.findAll(COLLECTION_NAME, {
        orderBy: {
            fieldPath: "position",
        },
    });

    const decksClone = _.cloneDeep(decks);
    const movedItem = decksClone.splice(oldIndex, 1)[0];

    decksClone.splice(newIndex, 0, movedItem);

    await firestoreRepository.batch((writeBatch) => {
        let newPosition = 0;
        for (const deckClone of decksClone) {
            newPosition++;
            if (deckClone.position == newPosition) continue;
            writeBatch.update(
                firestoreRepository.documentReference(
                    COLLECTION_NAME,
                    deckClone.id
                ),
                {
                    position: newPosition,
                }
            );
        }
    });
};
const addCardsToDeck = async (deckID: string, cards: Card[]): Promise<void> => {
    if (cards.length > 400) {
        throw "Currently it is not supported to add more than 400 cards in a batch to a deck.";
    }
    await firestoreRepository.batch((writeBatch) => {
        const tagsCount: Partial<Record<string, number>> = {};
        const updateCardGroups: Partial<Record<string, string[]>> = {};
        for (const card of cards) {
            writeBatch.update(
                firestoreRepository.documentReference(
                    cardRepository.COLLECTION_NAME,
                    card.id
                ),
                {
                    deckIds: arrayUnion(deckID),
                }
            );
            for (const tag of card.tags ?? []) {
                tagsCount[tag] = (tagsCount[tag] ?? 0) + 1;
            }
            const cardGroupIndex = `cardsGroups.players=${card.gameModeInfo
                .playersCount ?? 0},teams=${card.gameModeInfo.teamsCount ?? 0}`;
            updateCardGroups[cardGroupIndex] =
                updateCardGroups[cardGroupIndex] ?? [];
            updateCardGroups[cardGroupIndex]?.push(card.id);
        }
        const updateTagsDoc = {};
        for (const [tag, count] of Object.entries(tagsCount)) {
            updateTagsDoc[`tags.${tag}.count`] = increment(count ?? 0);
        }
        const firestoreUpdateCardGroups = {};
        for (const [cardGroupIndex, cardGroupCards] of Object.entries(
            updateCardGroups
        )) {
            firestoreUpdateCardGroups[cardGroupIndex] = arrayUnion(
                ...(cardGroupCards ?? [])
            );
        }
        writeBatch.update(
            firestoreRepository.documentReference(
                DECKS_DETAILS_COLLECTION_NAME,
                deckID
            ),
            {
                ...firestoreUpdateCardGroups,
                ...updateTagsDoc,
            }
        );
    });
};
const removeCardFromDeck = async (
    deckID: string,
    cards: Card[]
): Promise<void> => {
    if (cards.length > 400) {
        throw "Currently it is not supported to remove more than 400 cards in a batch from a deck.";
    }
    await firestoreRepository.batch((writeBatch) => {
        const tagsCount: Partial<Record<string, number>> = {};
        const updateCardGroups: Partial<Record<string, string[]>> = {};
        for (const card of cards) {
            writeBatch.update(
                firestoreRepository.documentReference(
                    cardRepository.COLLECTION_NAME,
                    card.id
                ),
                {
                    deckIds: arrayRemove(deckID),
                }
            );
            for (const tag of card.tags ?? []) {
                tagsCount[tag] = (tagsCount[tag] ?? 0) + 1;
            }
            const cardGroupIndex = `cardsGroups.players=${card.gameModeInfo
                .playersCount ?? 0},teams=${card.gameModeInfo.teamsCount ?? 0}`;
            updateCardGroups[cardGroupIndex] =
                updateCardGroups[cardGroupIndex] ?? [];
            updateCardGroups[cardGroupIndex]?.push(card.id);
        }
        const updateTagsDoc = {};
        for (const [tag, count] of Object.entries(tagsCount)) {
            updateTagsDoc[`tags.${tag}.count`] = increment(-(count ?? 0));
        }
        const firestoreUpdateCardGroups = {};
        for (const [cardGroupIndex, cardGroupCards] of Object.entries(
            updateCardGroups
        )) {
            firestoreUpdateCardGroups[cardGroupIndex] = arrayRemove(
                ...(cardGroupCards ?? [])
            );
        }
        writeBatch.update(
            firestoreRepository.documentReference(
                DECKS_DETAILS_COLLECTION_NAME,
                deckID
            ),
            {
                ...firestoreUpdateCardGroups,
                ...updateTagsDoc,
            }
        );
    });
};
export default {
    DECKS_DETAILS_COLLECTION_NAME,
    COLLECTION_NAME,
    getNewDeckId,
    findDeck,
    moveDeck,
    observeDeck,
    observeDeckDetails,
    saveDeck,
    addCardsToDeck,
    removeCardFromDeck,
    removeDeck,
};
