import settingsRepository from "@/repositories/settingsRepository";

export default {
    form: [
        {
            type: "comboChips",
            label: "Tags",
            name: "filters.tags",
            headerText: "components.combochips.header.search",
            actions: {
                create: false,
            },
            items: settingsRepository.observeCardTags,
        },
    ],

    validate: {},
};
