import Card from "@/model/Card";
import GameMode from "@/model/GameMode";
import LocalizedText from "@/model/LocalizedText";
import firestoreRepository from "./firestoreRepository";
import settingsRepository from "./settingsRepository";
import _ from "lodash-es";
import cardTypeRepository from "./cardTypeRepository";
import {
    increment,
    QueryConstraint,
    where,
    deleteField,
    arrayRemove,
    serverTimestamp,
    arrayUnion,
} from "firebase/firestore";
import { showMessage } from "@/helpers/messages";
import deckRepository from "./deckRepository";
import utils from "@/helpers/utils";

const COLLECTION_NAME = "cards";

const getNewCardId = (): string => {
    return firestoreRepository.getNewDocumentId(COLLECTION_NAME);
};

const findCards = async (
    options: { deckId?: string } = {}
): Promise<Card[]> => {
    const queryConstraints: QueryConstraint[] = [];
    if (options.deckId != undefined) {
        queryConstraints.push(
            where("deckIds", "array-contains", options.deckId)
        );
    }
    return await firestoreRepository.findAll(COLLECTION_NAME, {
        queryConstraints: queryConstraints,
    });
};
const findCard = async (id: string): Promise<Card | undefined> => {
    return await firestoreRepository.find(COLLECTION_NAME, id);
};
function findVariablesInText(text: string): string[] {
    const matches = utils.getMatches(text, /\${([^\s]+)}/g, 1);
    return matches;
}
function numberOfTeamsAndPlayersVariablesInText(
    text: string
): { teams: number; players: number } {
    const variables = findVariablesInText(text);
    const teams: number[] = [];
    const players: number[] = [];
    for (const variable of variables) {
        const variableFields = variable.split("-");
        if (variableFields.length != 2) {
            showMessage({
                text: `Variable ${variable} not valid`,
                type: "error",
            });
            throw "Variable not valid";
        }
        const variableType = variableFields[0];
        const variableCount = parseInt(variableFields[1]);
        if (variableCount < 1) {
            showMessage({
                text: `Variable count ${variable} not valid`,
                type: "error",
            });
            throw "Variable count not valid";
        }
        switch (variableType) {
            case "player":
                players.push(variableCount);
                break;
            case "team":
                teams.push(variableCount);
                break;
            default:
                showMessage({
                    text: `Unknown variable type ${variableType} found`,
                    type: "error",
                });
                throw "Unknown variable type found";
        }
    }
    for (let i = 1; i <= teams.length; i++) {
        if (!teams.includes(i)) {
            showMessage({
                text: `Invalid teams variables`,
                type: "error",
            });
            throw "Invalid teams variables";
        }
    }
    for (let i = 1; i <= players.length; i++) {
        if (!players.includes(i)) {
            showMessage({
                text: `Invalid players variables`,
                type: "error",
            });
            throw "Invalid players variables";
        }
    }
    return { teams: teams.length, players: players.length };
}
function numberOfTeamsAndPlayersVariablesInLocalizedText(
    localizedText: LocalizedText
): { teams: number; players: number } {
    let numberOfTeamsAndPlayers:
        | { teams: number; players: number }
        | undefined = undefined;

    for (const text of Object.values(localizedText)) {
        const newNumberOfTeamsAndPlayers = numberOfTeamsAndPlayersVariablesInText(
            text
        );
        if (numberOfTeamsAndPlayers == undefined) {
            numberOfTeamsAndPlayers = newNumberOfTeamsAndPlayers;
        } else if (
            numberOfTeamsAndPlayers.players !=
                newNumberOfTeamsAndPlayers.players ||
            numberOfTeamsAndPlayers.teams != numberOfTeamsAndPlayers.teams
        ) {
            throw "All localized texts should have the same number of variables";
        }
    }
    return numberOfTeamsAndPlayers ?? { teams: 0, players: 0 };
}
function validateTags(tags: string[]): boolean {
    for (const tag of tags) {
        if (!/^[0-9a-z_]+$/.test(tag)) {
            showMessage({
                text: `Tags can only contain lowercase characters, numbers and underscore`,
                type: "error",
            });
            throw "Invalid tag found";
        }
    }
    return true;
}
const saveCard = async (card: Card, create: boolean): Promise<void> => {
    const cardClone: Omit<Card, "id"> & { id?: string } = _.clone(card);
    delete cardClone.id;
    console.log(`Card type id ${cardClone.cardTypeId}`);
    const numberOfTeamsAndPlayers = numberOfTeamsAndPlayersVariablesInLocalizedText(
        cardClone.text
    );
    if (cardClone.followingCard != undefined) {
        const numberOfTeamsAndPlayersFollowingCard = numberOfTeamsAndPlayersVariablesInLocalizedText(
            cardClone.followingCard.text
        );
        cardClone.gameModeInfo = {
            playersCount: Math.max(
                numberOfTeamsAndPlayers.players,
                numberOfTeamsAndPlayersFollowingCard.players
            ),
            teamsCount: Math.max(
                numberOfTeamsAndPlayers.teams,
                numberOfTeamsAndPlayersFollowingCard.teams
            ),
        };
        cardClone.followingCard.showAsNext =
            cardClone.followingCard.showAsNext ?? false;
    } else {
        cardClone.gameModeInfo = {
            playersCount: numberOfTeamsAndPlayers.players,
            teamsCount: numberOfTeamsAndPlayers.teams,
        };
    }
    cardClone.deckIds = cardClone.deckIds ?? [];
    cardClone.tags = cardClone.tags ?? [];
    validateTags(cardClone.tags);
    switch (cardClone.gameMode) {
        case GameMode.AllVsAll:
            if (numberOfTeamsAndPlayers.teams > 0) {
                throw "Card all vs all can't have teams";
            }
            break;
        case GameMode.TeamVsTeam:
            break;
        default:
            console.error("Unknown game mode", cardClone.gameMode);
            throw "Unknown game mode";
    }

    const oldCard = await findCard(card.id);
    const addedTags = _.difference(cardClone.tags, oldCard?.tags ?? []);
    const removedTags = _.difference(oldCard?.tags ?? [], cardClone.tags);
    await firestoreRepository.batch((writeBatch) => {
        // Update Tags
        const updateTagsInDeckDoc = {};
        const updateTagsDoc = {};
        for (const tag of addedTags) {
            updateTagsDoc[`value.${tag}.count`] = increment(1);
            updateTagsInDeckDoc[`tags.${tag}.count`] = increment(1);
        }
        for (const tag of removedTags) {
            updateTagsDoc[`value.${tag}.count`] = increment(-1);
            updateTagsInDeckDoc[`tags.${tag}.count`] = increment(-1);
        }
        writeBatch.update(
            firestoreRepository.documentReference(
                settingsRepository.COLLECTION_NAME,
                settingsRepository.CARD_TAGS_KEY
            ),
            updateTagsDoc
        );
        // Update Card Types
        if (oldCard?.cardTypeId != cardClone.cardTypeId) {
            if (oldCard?.cardTypeId != undefined) {
                writeBatch.update(
                    firestoreRepository.documentReference(
                        cardTypeRepository.COLLECTION_NAME,
                        oldCard?.cardTypeId
                    ),
                    {
                        linkedCardsCount: increment(-1),
                    }
                );
            }
            if (cardClone.cardTypeId != undefined) {
                writeBatch.update(
                    firestoreRepository.documentReference(
                        cardTypeRepository.COLLECTION_NAME,
                        cardClone.cardTypeId
                    ),
                    {
                        linkedCardsCount: increment(1),
                    }
                );
            }
        }
        const oldFollowingCardTypeId = oldCard?.followingCard?.cardTypeId;
        const newFollowingCardTypeId = cardClone.followingCard?.cardTypeId;

        if (oldFollowingCardTypeId != newFollowingCardTypeId) {
            if (oldFollowingCardTypeId != undefined) {
                writeBatch.update(
                    firestoreRepository.documentReference(
                        cardTypeRepository.COLLECTION_NAME,
                        oldFollowingCardTypeId
                    ),
                    {
                        linkedCardsCount: increment(-1),
                    }
                );
            }
            if (newFollowingCardTypeId != undefined) {
                writeBatch.update(
                    firestoreRepository.documentReference(
                        cardTypeRepository.COLLECTION_NAME,
                        newFollowingCardTypeId
                    ),
                    {
                        linkedCardsCount: increment(1),
                    }
                );
            }
        }
        if (
            cardClone.deckIds.length > 0 &&
            !_.isEqual(oldCard?.gameModeInfo, cardClone.gameModeInfo)
        ) {
            for (const deckId of cardClone.deckIds) {
                if (oldCard?.gameModeInfo != undefined) {
                    const oldCardGroupIndex = `cardsGroups.players=${oldCard
                        .gameModeInfo.playersCount ?? 0},teams=${oldCard
                        .gameModeInfo.teamsCount ?? 0}`;
                    console.log("Removing from old array");
                    writeBatch.update(
                        firestoreRepository.documentReference(
                            deckRepository.DECKS_DETAILS_COLLECTION_NAME,
                            deckId
                        ),
                        {
                            [oldCardGroupIndex]: arrayRemove(card.id),
                        }
                    );
                }
                const cardGroupIndex = `cardsGroups.players=${cardClone
                    .gameModeInfo.playersCount ?? 0},teams=${cardClone
                    .gameModeInfo.teamsCount ?? 0}`;
                console.log("Adding to new array");
                writeBatch.update(
                    firestoreRepository.documentReference(
                        deckRepository.DECKS_DETAILS_COLLECTION_NAME,
                        deckId
                    ),
                    {
                        [cardGroupIndex]: arrayUnion(card.id),
                    }
                );
            }
        }
        // Update decks tags count
        for (const deckId of cardClone.deckIds) {
            writeBatch.update(
                firestoreRepository.documentReference(
                    deckRepository.DECKS_DETAILS_COLLECTION_NAME,
                    deckId
                ),
                updateTagsInDeckDoc
            );
        }
        // Save card
        if (cardClone.followingCard == undefined) {
            cardClone.followingCard = deleteField() as any;
        }
        if (create) {
            (cardClone as any).createdAt = serverTimestamp();
        } else {
            delete (cardClone as any).createdAt;
        }
        const docRef = firestoreRepository.documentReference(
            COLLECTION_NAME,
            card.id
        );
        writeBatch.set(docRef, cardClone, { merge: true });
    });
};
const removeCard = async (id: string): Promise<void> => {
    const card = await findCard(id);
    if (card == undefined) return;
    await firestoreRepository.batch((writeBatch) => {
        const decrementTagsInDeckDoc = {};
        const decrementTagsDoc = {};
        for (const tag of card.tags) {
            decrementTagsDoc[`value.${tag}.count`] = increment(-1);
            decrementTagsInDeckDoc[`tags.${tag}.count`] = increment(-1);
        }
        // Decrement linked card type count
        const cardTypeId = card.cardTypeId;
        if (cardTypeId != undefined) {
            writeBatch.update(
                firestoreRepository.documentReference(
                    cardTypeRepository.COLLECTION_NAME,
                    cardTypeId
                ),
                {
                    linkedCardsCount: increment(-1),
                }
            );
        }
        const followingCardTypeId = card.followingCard?.cardTypeId;
        if (followingCardTypeId != undefined) {
            writeBatch.update(
                firestoreRepository.documentReference(
                    cardTypeRepository.COLLECTION_NAME,
                    followingCardTypeId
                ),
                {
                    linkedCardsCount: increment(-1),
                }
            );
        }

        for (const deckId of card.deckIds) {
            const cardGroupIndex = `cardsGroups.players=${card.gameModeInfo
                .playersCount ?? 0},teams=${card.gameModeInfo.teamsCount ?? 0}`;
            writeBatch.update(
                firestoreRepository.documentReference(
                    deckRepository.DECKS_DETAILS_COLLECTION_NAME,
                    deckId
                ),
                {
                    ...decrementTagsInDeckDoc,
                    [cardGroupIndex]: arrayRemove(card.id),
                }
            );
        }

        // Decrement settings tags count
        writeBatch.update(
            firestoreRepository.documentReference(
                settingsRepository.COLLECTION_NAME,
                settingsRepository.CARD_TAGS_KEY
            ),
            decrementTagsDoc
        );
        writeBatch.delete(
            firestoreRepository.documentReference(COLLECTION_NAME, id)
        );
    });
    await firestoreRepository.remove(COLLECTION_NAME, id);
};

export default {
    COLLECTION_NAME,
    getNewCardId,
    saveCard,
    findCards,
    findCard,
    removeCard,
};
