<template>
    <v-container>
        <v-row>
            <v-col
                v-for="(field, key) in configForm"
                v-if="!hiddenField(field)"
                :key="key"
                :cols="setColumnLenght(field)"
            >
                <component
                    :is="field.type"
                    :name="field.name"
                    :params="field.params"
                    :field="field"
                    :model="formValues"
                    :element="field.name"
                    :validate="$v"
                    :optionsComponent="setOptions(field)"
                    @onComponentEvent="setComponentEvent"
                >
                </component>

                <template v-for="(errorClass, error) in errorConfig">
                    <p
                        v-if="hasOwnProperty($v.formValues[field.name], error)"
                        :class="errorClass"
                        :key="error"
                    >
                        {{ getErrorMessage(field, error) }}
                    </p>
                </template>
            </v-col>
        </v-row>
        <v-row>
            <v-spacer />
            <v-btn
                color="blue darken-1"
                :hidden="buttons.captionNo == undefined"
                :disabled="actionsDisabled"
                text
                @click="onClose"
                >{{ $t(buttons.captionNo) }}</v-btn
            >
            <v-btn
                color="blue darken-1"
                text
                variant="success"
                type="submit"
                @click="onSubmit()"
                :disabled="$v.$invalid || actionsDisabled"
                >{{ $t(buttons.captionYes) }}</v-btn
            >
        </v-row>
    </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import TextField from "./elements/TextField";
import TextArea from "./elements/TextArea";
import CheckBox from "./elements/CheckBox";
import ColorField from "./elements/ColorField";
import Spacer from "./elements/Spacer";
import CSelect from "./elements/CSelect";
import ComboBox from "./elements/ComboBox";
import FileUpload from "./elements/FileUpload";
import ComboChips from "./elements/ComboChips";

const ERROR_CONFIG = {
    required: "help is-danger",
};

export default {
    name: "FormBuilder",
    mixins: [validationMixin],
    props: {
        title: {
            type: String,
            default: "titolo non impostato",
        },
        initialValues: {
            type: Object,
            default: null,
        },
        configForm: {
            type: Array,
            default() {
                return [];
            },
        },
        buttons: {
            type: Object,
            default() {
                return {
                    captionNo: "No",
                    captionYes: "Yes",
                };
            },
        },
        validation: {
            type: Object,
            default: null,
        },
        resetForm: {
            type: Boolean,
            default: false,
        },
        actionsDisabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        TextField,
        TextArea,
        ColorField,
        CheckBox,
        FileUpload,
        ComboChips,
        ComboBox,
        CSelect,
        Spacer,
    },
    data() {
        return {
            formValues: {},
            files: {},
            disabled: false,
            errorConfig: ERROR_CONFIG,
        };
    },

    validations() {
        return {
            formValues: this.rules,
        };
    },
    created() {
        this.formValues = flatten(this.initialValues);
    },
    computed: {
        rules() {
            return this.$props.validation;
        },

        hiddenField: () => {
            return function (field) {
                let result = false;

                if (field.hidden) {
                    result = field.hidden;
                }

                if (!result) {
                    if (field.show) {
                        let isVisible = true;
                        for (const showItem of field.show) {
                            if (this.formValues[showItem.element]) {
                                isVisible =
                                    isVisible &&
                                    (showItem.values.includes(
                                        this.formValues[showItem.element].value
                                    ) ||
                                        showItem.values.includes(
                                            this.formValues[showItem.element]
                                        ));
                            } else {
                                isVisible = false;
                            }
                        }
                        result = !isVisible;
                    }
                }

                return result;
            };
        },
    },
    methods: {
        setOptions(field) {
            return field.options ? field.options : {};
        },

        setComponentEvent({ action, data }) {
            this.$emit("onComponentEvent", { action, data });
        },

        setColumnLenght(field) {
            if (field.hasOwnProperty("col")) {
                return field.col;
            } else {
                return 12;
            }
        },
        onSubmit() {
            this.$emit("onSubmitForm", unflatten(this.formValues));
        },
        onClose() {
            this.$emit("onClose");
        },

        getErrorMessage(field, error) {
            let message = field.error[error];
            if (typeof message === "function") {
                message = message.call(
                    null,
                    this.$v.formValues[field.name].$params[error]
                );
            }
            return message;
        },
    },
};
// It doesn't flatten arrays
function flatten(data) {
    var result = {};
    function recurse(cur, prop) {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
            result[prop] = cur;
        } else {
            var isEmpty = true;
            for (var p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop + "." + p : p);
            }
            if (isEmpty && prop) result[prop] = {};
        }
    }
    recurse(data, "");
    return result;
}
function unflatten(data) {
    "use strict";
    if (Object(data) !== data || Array.isArray(data)) return data;
    var regex = /\.?([^.\[\]]+)|\[(\d+)\]/g,
        resultholder = {};
    for (var p in data) {
        var cur = resultholder,
            prop = "",
            m;
        while ((m = regex.exec(p))) {
            cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
            prop = m[2] || m[1];
        }
        cur[prop] = data[p];
    }
    return resultholder[""] || resultholder;
}
</script>

<style lang="scss" scoped>
.subTitle {
    color: #6c757d;
}

/***Errors***/
.help {
    font-size: 15px;
    &.error {
        color: red;
    }
    &.required {
        color: #6c757d;
    }
}
</style>
