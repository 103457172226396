<template>
    <div>
        <div class="text-caption pb-2">{{ field.label }}</div>
        <v-text-field
            v-model="model[element]"
            v-mask="mask"
            :placeholder="field.label"
            :readonly="field.readonly"
            hide-details
            class="ma-0 pa-0"
            solo
        >
            <template v-slot:append>
                <v-menu
                    v-model="menu"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                >
                    <template v-slot:activator="{ on }">
                        <div :style="swatchStyle" v-on="on" />
                    </template>
                    <v-card>
                        <v-card-text class="pa-0">
                            <v-color-picker v-model="model[element]" flat />
                        </v-card-text>
                    </v-card>
                </v-menu>
            </template>
        </v-text-field>
    </div>
</template>

<script>
import elementsMixin from "./elementsMixin";
import { mask } from "vue-the-mask";
export default {
    name: "colorField",
    mixins: [elementsMixin],
    directives: { mask },
    data: () => ({
        mask: "!#XXXXXX",
        hexTokens: {
            F: {
                pattern: /#|[0-9a-fA-F]/,
                transform: (v) => v.toLocaleUpperCase(),
            },
        },
        menu: false,
    }),
    mounted() {
        if (this.model[this.element] == undefined) {
            console.error(
                "ColorField: no default value set, behavior will be unexpected"
            );
        }
    },
    computed: {
        swatchStyle() {
            const color = this.model[this.element];
            const { menu } = this;
            return {
                backgroundColor: color,
                cursor: "pointer",
                height: "30px",
                width: "30px",
                borderRadius: menu ? "50%" : "4px",
                transition: "border-radius 200ms ease-in-out",
            };
        },
    },
};
</script>

<style lang="scss">
.text-field-line-height {
    height: 5px;
}
</style>
