import utils from "@/helpers/utils";

function getComputedKeyPath(
    rawKey: string,
    model: Partial<Record<string, any>> | undefined
): string {
    if (model == undefined) return rawKey;
    let retKey = rawKey;
    const regex = /\${(\w+)}/g;
    const matches = utils.getMatches(rawKey, regex, 1) ?? [];
    for (const match of matches) {
        let path = model[match];
        if (typeof path != "string") {
            path = path?.value;
        }
        retKey = retKey.replaceAll("${" + match + "}", path);
    }
    return retKey;
}
export default {
    getComputedKeyPath,
};
