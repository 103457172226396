<template>
    <div>
        <v-file-input
            v-if="!imagePreviewURL"
            prepend-icon="mdi-file-image"
            placeholder="thumbnail"
            @change="changeFile"
            accept="image/*"
            :label="field.label"
            :error-messages="errorMessages"
            v-model="model[element]"
        >
        </v-file-input>
        <v-card
            v-if="model[element] && errorMessages === ''"
            max-width="30%"
            class="ma-2 pa-2"
            elevation="2"
        >
            <v-btn
                class="close"
                dark
                fab
                x-small
                right
                top
                @click="removeImage"
                color="primary"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <div class="justify-center">
                <v-img v-if="imagePreviewURL" :src="imagePreviewURL"> </v-img>
            </div>
        </v-card>
    </div>
</template>

<script>
import elementsMixin from "./elementsMixin";
import Vue from "vue";
export default {
    name: "FileUpload",
    mixins: [elementsMixin],

    data() {
        return {
            imagePreviewURL: null,
            errorMessages: "",
        };
    },
    async created() {
        if (
            this.model[this.element] !== undefined &&
            this.field.hasOwnProperty("imagePreview")
        ) {
            this.imagePreviewURL = await this.field.imagePreview();
        }
    },
    methods: {
        async changeFile(payload) {
            if (this.field.rulesFile) {
                let error = await this.field.rulesFile(payload);
                this.errorMessages = error
                    ? this.$t(error.text, error.params)
                    : "";
            }

            if (this.errorMessages === "") {
                this.previewImage(payload);
            } else {
                this.removeImage();
            }
        },

        previewImage(payload) {
            Vue.set(this.model, this.element, payload);
            if (payload) {
                this.imagePreviewURL = URL.createObjectURL(payload);
                URL.revokeObjectURL(payload);
                this.$emit("onComponentEvent", { action: "refreshFileUpload" });
            } else {
                this.imagePreviewURL = null;
            }
        },

        removeImage() {
            Vue.set(this.model, this.element, undefined);
            this.imagePreviewURL = null;
        },
    },
};
</script>

<style lang="scss" scoped>
.close {
    position: absolute;
    width: 30px;
    height: 30px;
    z-index: 10;
    top: -10px;
    right: -10px;
}
</style>
