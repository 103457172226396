import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "@/views/Login.vue";
import Cards from "@/views/Cards.vue";
import CardTypes from "@/views/CardTypes.vue";
import Decks from "@/views/Decks.vue";
import Deck from "@/views/Deck.vue";
import auth from "./auth";
Vue.use(VueRouter);
import store from "./store";

const routes: RouteConfig[] = [
    {
        path: "*",
        component: () =>
            import(/* webpackChunkName: "about" */ "./views/Error404.vue"),
    },

    { path: "/", redirect: "/decks" },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },

    {
        path: "/cards",
        name: "Cards",
        meta: {
            requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Cards,
        //component: () => import(/* webpackChunkName: "about" */ './views/VideoClip.vue')
    },

    {
        path: "/cardTypes",
        name: "Card Types",
        meta: {
            requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: CardTypes,
        //component: () => import(/* webpackChunkName: "about" */ './views/VideoClip.vue')
    },

    {
        path: "/decks",
        name: "Decks",
        meta: {
            requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Decks,
        //component: () => import(/* webpackChunkName: "about" */ './views/VideoClip.vue')
    },
    {
        path: "/deck/:id",
        name: "Deck",
        meta: {
            requiresAuth: true,
        },
        component: Deck,
        props: true,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "about" */ './views/VideoClip.vue')
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
    base: process.env.BASE_URL,
});

function routerAuth(requiresAuth, loggedIn, next) {
    if (requiresAuth && !loggedIn) {
        next("/login");
    } else {
        next();
    }
}

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    let loggedIn = store.state.user.loggedIn;
    if (loggedIn === undefined) {
        (async () => {
            loggedIn = await auth.checkAuthStatus();
            routerAuth(requiresAuth, loggedIn, next);
        })();
    } else {
        routerAuth(requiresAuth, loggedIn, next);
    }
});

export default router;
