import Vue from "vue";
export default (formConfigSchema = null, formName = "") => {
    return Vue.extend({
        props: {
            data: {
                type: Object,
                default: null,
            },
        },
        data(): {
            refreshFileUpload: boolean;
            buttonsForm: {
                captionNo?: string;
                captionYes: string;
            };
        } {
            return {
                refreshFileUpload: false,
                buttonsForm: {
                    captionYes: "buttons.actions.save",
                },
            };
        },

        computed: {
            formConfig() {
                return formConfigSchema;
            },

            initDataForm() {
                if (!this.data.init) {
                    return this.data;
                } else {
                    return Object.prototype.hasOwnProperty.call(
                        this.data,
                        "init"
                    )
                        ? this.data.init
                        : null;
                }
            },
        },

        methods: {
            closeForm() {
                this.$emit("onClose");
            },

            setFunctionDownloadImage(fieldName) {
                (this.formConfig as any).form.map((item) => {
                    if (item.name === fieldName) {
                        item.imagePreview = () =>
                            (this as any).downloadImage(
                                this.initDataForm[fieldName]
                            );
                    }
                    return item;
                });
            },

            execEventComponent({ action, data }): void {
                switch (action) {
                    case "refreshFileUpload":
                        this.refreshFileUpload = true;
                        break;
                    case "another":
                        break;
                    default:
                }
            },

            saveDataForm(data): void {
                if (this.data !== null) {
                    const idChild = data.idChild ? data.idChild : data.id;

                    data = {
                        ...{
                            outputData: {
                                ...data,
                                ...{
                                    idChild,
                                    entity: (this as any).entity,
                                    entityChild: (this as any).entityChild,
                                },
                            },
                            ...{ inputData: this.data },
                        },
                    };
                    data = { ...data, ...{ name: formName } };

                    this.$emit("onDataEvent", data);
                    this.closeForm();
                }
            },
        },
    });
};
