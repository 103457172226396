<template>
    <div>
        <v-spacer></v-spacer>
    </div>
</template>

<script>
import elementsMixin from "./elementsMixin";
export default {
    name: "spacer",
    mixins: [elementsMixin],
};
</script>