import { LocaleMessages } from "vue-i18n";

export const DICTIONARY: LocaleMessages = {
    en: {
        components: {
            combochips: {
                create: "Press enter to create a tag",
                header: {
                    create: "Select or create tags",
                    search: "Select tags",
                },
            },
        },

        buttons: {
            actions: {
                add: "Add",
                delete: "Delete",
                update: "Edit",
                find: "Search",
                new: "New",
                reset: "Reset",
                close: "Cancel",
                save: "Confirm",
            },
        },
        word: {
            deck: "Deck",
            decks: "Decks",
            cardTypes: "Card Types",
            cards: "Cards",
            card: "Card",
        },

        text: {
            addCardsToDeck: "Add cards to deck",
            newCardType: "New card type",
            newCard: "New card",
            newDeck: "New deck",
            editCardType: "Edit card type",
            editCard: "Edit card",
            editDeck: "Edit deck",
        },

        rules: {
            image: {
                format: "Valid format:{x} x {y}",
            },
        },
        message: {
            save: {
                item: {
                    update: "Item has been edited successfully",
                    add: "Item has been added successfully",
                },
            },
        },

        confirm: {
            delete: {
                item: {
                    title: "Delete item",
                    message:
                        "You're deleting <b>{name}</b item permanently. Do you want to proceed anyway?",
                },
            },
        },
    },
};
export default {
    DICTIONARY,
};
