import CardType from "@/model/CardType";
import firestoreRepository from "./firestoreRepository";
import _ from "lodash-es";
import { serverTimestamp } from "firebase/firestore";

const COLLECTION_NAME = "cardTypes";

const getNewCardTypeId = (): string => {
    return firestoreRepository.getNewDocumentId(COLLECTION_NAME);
};

const findCardType = async (id: string): Promise<CardType | undefined> => {
    return await firestoreRepository.find(COLLECTION_NAME, id);
};
const observeCardTypes = (
    onNext: (result: CardType[]) => void,
    onError: (error: { code: string; message: string }) => void
): void => {
    firestoreRepository.observeAll(COLLECTION_NAME, {}, onNext as any, onError);
};
const saveCardType = async (
    cardType: CardType,
    create: boolean
): Promise<void> => {
    const cardTypeClone: Partial<CardType> = _.clone(cardType);
    delete cardTypeClone.id;
    if (cardType.linkedCardsCount == undefined) {
        cardType.linkedCardsCount = 0;
    }
    if (create) {
        (cardTypeClone as any).createdAt = serverTimestamp();
    } else {
        delete (cardTypeClone as any).createdAt;
    }
    await firestoreRepository.set(COLLECTION_NAME, {
        documentId: cardType.id,
        data: cardTypeClone,
        merge: true,
    });
};
const removeCardType = async (id: string): Promise<void> => {
    const cardType = await findCardType(id);
    if (cardType == undefined) return;
    if (cardType.linkedCardsCount > 0) {
        throw "You can't delete a card type with cards linked";
    }
    await firestoreRepository.remove(COLLECTION_NAME, id);
};

export default {
    COLLECTION_NAME,
    getNewCardTypeId,
    saveCardType,
    observeCardTypes,
    findCardType,
    removeCardType,
};
