import Vue from "vue";
export default Vue.extend({
    props: {
        model: {
            type: Object,
            default: null,
        },
        element: {
            type: String,
            default: "",
        },
        field: {
            type: Object,
            default: {},
        },
        validate: {
            type: Object,
            default: {},
        },
        optionsComponent: {
            type: Object,
            default: {},
        },
    },

    methods: {
        getReadOnlyField(field) {
            return !field.editable;
        },
    },
});
