function getMatches(string: string, regex: RegExp, index: number) {
    index || (index = 1); // default to the first capturing group
    const matches: string[] = [];
    let match: RegExpExecArray | null;
    while ((match = regex.exec(string))) {
        matches.push(match?.[index]);
    }
    return matches;
}
export default {
    getMatches,
};
