<template>
    <div>
        <v-checkbox
            v-model="model[element]"
            :label="field.label"
            :readonly="field.readonly"
            hide-details
            class="ma-0 pa-0"
            solo
        >
        </v-checkbox>
    </div>
</template>

<script>
import elementsMixin from "./elementsMixin";
export default {
    name: "checkBox",
    mixins: [elementsMixin],
};
</script>