export default {
    MODE: "prod",
    NAME_SPACE: "backoffice",
    RECAPTCHA_SITE_KEY: "6LccyT8cAAAAADWd6c0pmRRhn7cpMN5u-AYp_KzG",
    FIREBASE_CONFIG: {
        apiKey: "AIzaSyCmogn_-9LZezN5cRlkMMO7ALBbfLMCu_Y",
        authDomain: "drinking-game-e6185.firebaseapp.com",
        projectId: "drinking-game-e6185",
        storageBucket: "drinking-game-e6185.appspot.com",
        messagingSenderId: "447886563033",
        appId: "1:447886563033:web:54cecb90e692c9a440d0c8",
        measurementId: "G-HSNS9M6W7G",
    },
};
